import React, { useEffect, useState } from "react";
import "./MatchSchedule.css";
import { Link, useNavigate } from "react-router-dom";
import image500ae from "../../../assets/image/2.webp";
import trandaukhac from '../../../assets/image/trandaukhac.webp'
const MatchSchedule = ({ filteredData }) => {
  const [extraData, setExtraData] = useState(null);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const navigate = useNavigate();

  const handleViewNow = (roomId) => {
    navigate(`/rooms/${roomId}`);
  };

  const fetchExtraData = async () => {
    try {
      const response = await fetch("https://spapi.zetcdn.site/livedata.json");
      if (!response.ok) {
        throw new Error("Error fetching extra data: " + response.statusText);
      }
      const extraData = await response.json();
      setExtraData(extraData);
    } catch (error) {
      console.error("Problem with extra data fetch:", error);
    }
  };

  const statusMapping = {
    1: "NO",
    2: "H1",
    3: "HT",
    4: "H2",
    5: "HP",
    6: "HP",
    7: "PEN",
    8: "END",
    9: "STOP",
    10: "STOP",
    11: "CUT",
    12: "CANCEL",
    13: "VS",
  };
  useEffect(() => {
    fetchExtraData()
  }, [])
  return (
    <div>
      <div className="MS_title">
        <img src={trandaukhac} width="100%" alt="" />
      </div>
      <div className="matchSchedule">
        {extraData && filteredData && filteredData.map((match, index) => {

          const matchingExtraData = extraData.find(
            (item) => item.matchId === match.matchId
          );
          const score = matchingExtraData
            ? matchingExtraData.score.replace(",", " - ")
            : "0 - 0";
          const statusText = matchingExtraData
            ? statusMapping[matchingExtraData.status] : "VS";
          return (
            <Link
              to={`/rooms/${match._id}`}
              style={{ textDecoration: "none", color: "white" }}
              key={index}
            >
              <div
                className="match_schedule"
                key={index}
              >
                <div className="ms-first-row">
                  <div className="ms-description">
                    <p>{truncateText(match.description, 15)}</p>
                  </div>
                  <div className="living-group">

                    <span>{match.time}</span>
                  </div>
                </div>
                <div className="match_name">
                  <div className="match_team_one ">
                    <div className="text-center">
                      <div >
                        <img style={{ backgroundColor: 'white', borderRadius: '50%', padding: '1px' }}
                          src={match?.homeLogo || image500ae}

                          onError={(e) => {
                            e.target.onerror = null; // Prevent looping if the fallback also fails
                            e.target.src = image500ae; // Replace with the actual path to image500ae
                          }}
                          width="25px"
                          height="25px"
                          alt={`${match.homeTeam} logo`}
                        />
                      </div>
                      <p>{truncateText(match.homeTeam, 8)}</p>
                    </div>
                  </div>
                  <div className="match-middle-content_mobile" bis_skin_checked="1">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="match-time_mobile" bis_skin_checked="1"> <p>{statusText}</p> </div>
                    </div>
                    <div className="match-vs_mobile" bis_skin_checked="1"><p>{score}</p></div>
                  </div>
                  <div className="match_team_two">
                    <div className="text-center">
                      <div >
                        <img style={{ backgroundColor: 'white', borderRadius: '50%', padding: '1px' }}
                          src={match?.awayLogo || image500ae}

                          onError={(e) => {
                            e.target.onerror = null; // Prevent looping if the fallback also fails
                            e.target.src = image500ae; // Replace with the actual path to image500ae
                          }}
                          width="25px"
                          height="25px"
                          alt={`${match.awayTeam} logo`}
                          className=""
                        />
                      </div>
                      <p> {truncateText(match.awayTeam, 5)}</p>
                    </div>
                  </div>
                </div>
                <div className="match_time">
                  <button>
                    XEM NGAY
                    <i className="fa-regular fa-circle-play ms-1"></i>
                  </button>
                  <button >
                    BLV: {match.name}
                  </button>
                </div>
              </div>
            </Link>
          )
        })}
      </div>

    </div>
  );
};

export default MatchSchedule;
