import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import footballvideo from '../../../assets/video/football.mp4';
import './VideoLive.css';

const VideoLive = ({ data }) => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const m3u8Url = typeof data === "string"
        ? data
        : data[0]?.m3u8 || data[0]?.m3u8hd || data[0]?.cdnlink;

    const handleUserInteraction = () => {
        if (videoRef.current && !isPlaying) {
            videoRef.current.muted = false;
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        const setupVideo = () => {
            if (m3u8Url && videoRef.current) {
                if (m3u8Url.endsWith('.mp4')) {
                    videoRef.current.src = m3u8Url;
                    videoRef.current.muted = true;
                    videoRef.current.play().catch(() => {

                    });
                } else if (Hls.isSupported()) {
                    const hls = new Hls();
                    hls.loadSource(m3u8Url);
                    hls.attachMedia(videoRef.current);

                    hls.on(Hls.Events.MANIFEST_PARSED, function () {
                        videoRef.current.muted = true;
                        videoRef.current.play().catch(() => {

                        });
                    });

                    return () => {
                        hls.destroy();
                    };
                } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                    videoRef.current.src = m3u8Url;
                    videoRef.current.muted = true;
                    videoRef.current.play().catch(() => {

                    });
                } else {
                    videoRef.current.src = footballvideo;
                    videoRef.current.muted = true;
                    videoRef.current.play().catch(() => {

                    });
                }
            }
        };

        if (videoRef.current) {
            setupVideo();
        }

        return () => {
            // Cleanup if necessary
        };
    }, [m3u8Url]);

    return (
        <div className='video_main'>
            <video
                ref={videoRef}
                controls
                playsInline // Prevents fullscreen on iOS
                webkit-playsinline="true" // Older versions of iOS Safari might require this
                onClick={handleUserInteraction}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                style={{ backgroundColor: 'black' }}>
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoLive;
