import React, { useEffect, useState } from "react";
import "./MatchVideo.css";
import Video from "../Video/Video";
import MatchSchedule from "../matchtime/MatchSchedule";
import PartnerLive from "../partnerlive/PartnerLive";
const MatchVideo = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [partnerLiveData, setPartnerLiveData] = useState(null)
  const handleParnterData = (data) => {
    setPartnerLiveData(data)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/rooms/list`
        );
        const result = await response.json();
        const filteredDataCdn = result.rooms
          .filter((match) => match.cdnlink !== "1")
          .sort((a, b) => {
            const dateTimeA = new Date(`${a.date}T${a.time}`);
            const dateTimeB = new Date(`${b.date}T${b.time}`);
            return dateTimeA - dateTimeB;
          });
        const sortedData = filteredDataCdn.sort((a, b) => (b.hot === "YES") - (a.hot === "YES"));
        const filteredData = sortedData.filter((match) => match.name !== "BLV Giang A Cay");
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (data.length > 0) {
    return (
      <div className="match_video">

        {data && (
          <>
            <Video data={data}></Video>
            <MatchSchedule filteredData={data}></MatchSchedule>
            <PartnerLive onData={handleParnterData}></PartnerLive>

          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="match_video">
        {data && (
          <>
            {partnerLiveData && <Video data={partnerLiveData}></Video>}
            <PartnerLive onData={handleParnterData}></PartnerLive>
          </>
        )}
      </div>
    );
  }
};

export default MatchVideo;
