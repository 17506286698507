import React, { useEffect, useState } from "react";
import "./MatchSchedulePc.css";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../../assets/image/arrow.webp";
import image500ae from "../../../assets/image/2.webp";

const MatchSchedulePc = ({ data, onScore }) => {
  const arrayScore = []
  const [visibleMatches, setVisibleMatches] = useState(6);
  const [isExpanded, setIsExpanded] = useState(false);
  const [extraData, setExtraData] = useState(null);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleSeeMore = () => {
    setVisibleMatches(data.length);
    setIsExpanded(true);
  };

  const handleCollapse = () => {
    setVisibleMatches(6);
    setIsExpanded(false);
  };

  const navigate = useNavigate();

  const handleViewNow = (roomId) => {
    navigate(`/rooms/${roomId}`);
  };
  const fetchExtraData = async () => {
    try {
      const response = await fetch("https://spapi.zetcdn.site/livedata.json");
      if (!response.ok) {
        throw new Error("Error fetching extra data: " + response.statusText);
      }
      const extraData = await response.json();
      setExtraData(extraData);
    } catch (error) {
      console.error("Problem with extra data fetch:", error);
    }
  };
  const statusMapping = {
    1: "NO",
    2: "H1",
    3: "HT",
    4: "H2",
    5: "HP",
    6: "HP",
    7: "PEN",
    8: "END",
    9: "STOP",
    10: "STOP",
    11: "CUT",
    12: "CANCEL",
    13: "VS",
  };



  useEffect(() => {
    fetchExtraData()
  }, [])
  return (
    <div className="matchSchedule_Pc_main">
      {extraData && data.slice(0, visibleMatches).map((match, index) => {
        const matchingExtraData = extraData.find(
          (item) => item.matchId === match.matchId
        );
        const score = matchingExtraData
          ? matchingExtraData.score.replace(",", " - ")
          : "0 - 0";
        const statusText = matchingExtraData
          ? statusMapping[matchingExtraData.status] : "VS";
        if (arrayScore.length < 1) {
          arrayScore.push(score)
          onScore(arrayScore)
        }
        return (
          <Link
            to={`/rooms/${match._id}`}
            style={{ textDecoration: "none", color: "white" }}
            key={index}
          >
            <div className="match_schedule_Pc d-flex align-items-center justify-content-around">
              <div className="match_league_Pc">
                {(
                  <div className='league_text'>{match.description}</div>
                )}
                {(
                  <div className="match_live_symbol">
                    <div className="ms-1">{match.time}</div>
                  </div>
                )}
              </div>
              <div className="match_name_Pc">
                <div className="match_team_one_Pc">
                  <div style={{ backgroundColor: 'white', borderRadius: '50%', padding: '2px', overflow: 'hidden' }}>
                    <img
                      src={match?.homeLogo || image500ae}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = image500ae;
                      }}
                      width="30px"
                      height="30px"
                      alt={`${match.homeTeam} logo`}
                    />
                  </div>
                  <p>{truncateText(match.homeTeam, 7)}</p>
                </div>
                <div className="match-middle-content">
                  <div className="match-time">

                    {statusText}
                  </div>
                  {

                  }
                  <div className="match-vs">{score}</div>
                </div>
                <div className="match_team_two_Pc">
                  <div style={{ backgroundColor: 'white', borderRadius: '50%', padding: '2px', overflow: 'hidden' }}>
                    <img
                      src={match?.awayLogo || image500ae}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent looping if the fallback also fails
                        e.target.src = image500ae; // Replace with the actual path to image500ae
                      }}
                      width="30px"
                      height="30px"
                      alt={`${match.awayTeam} logo`}
                      className=""
                    />
                  </div>
                  <p>{truncateText(match.awayTeam, 7)}</p>
                </div>
              </div>

              <div className="match_time_Pc_button">
                <button>
                  XEM NGAY
                  <i className="fa-regular fa-circle-play ms-1"></i>
                </button>
                <button>{match.name}</button>
              </div>
            </div>
          </Link>
        )
      })}
      <div className="match_see_more_Pc">
        {!isExpanded && visibleMatches < data.length && (
          <div className="MSPC-viewmore" onClick={handleSeeMore}>
            <a href="#/" className="">
              XEM THÊM
            </a>
            <img src={arrow} alt="" />
          </div>
        )}
        {isExpanded && (
          <div onClick={handleCollapse}>
            <a href="#/" className="">
              THU GỌN
            </a>
            <i className="fa-solid fa-arrow-up"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchSchedulePc;
