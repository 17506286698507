import { React, useEffect, useState } from 'react';
import useStore from '../../store/useStore';
import { Link, useNavigate } from "react-router-dom";
import image500ae from "../../../assets/image/2.webp";
import '../matchtime/MatchSchedule.css'



const PartnerLive = ({ onData }) => {

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const setVideoList = useStore((state) => state.setVideoList);
    const [extraData, setExtraData] = useState(null);

    const handleViewNow = (roomId) => {
        navigate(`/partnervideo/${roomId}`);
    };
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };



    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/rooms/partnerlive`;
        try {
            const response = await fetch(url, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('CHƯA CÓ TRẬN ĐẤU NÀO CỦA KÊNH NHÀ ĐÀI' + response.statusText);
            }
            const data = await response.json();
            setData(data);
            setVideoList(data);
            onData(data)
        } catch (error) {
            setError(error.message);
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    const fetchExtraData = async () => {
        try {
            const response = await fetch("https://spapi.zetcdn.site/livedata.json");
            if (!response.ok) {
                throw new Error("Error fetching extra data: " + response.statusText);
            }
            const extraData = await response.json();
            setExtraData(extraData);
        } catch (error) {
            console.error("Problem with extra data fetch:", error);
        }
    };
    const extractTime = (dateTimeString) => {
        return dateTimeString.split(" ")[1] || "";
    };
    const statusMapping = {
        1: "NO",
        2: "H1",
        3: "HT",
        4: "H2",
        5: "HP",
        6: "HP",
        7: "PEN",
        8: "END",
        9: "STOP",
        10: "STOP",
        11: "CUT",
        12: "CANCEL",
        13: "VS",
    };

    useEffect(() => {
        fetchData();
        fetchExtraData()
    }, []);

    if (error) return <div className='text-center mb-2'>{error}</div>;

    if (data) {
        return (
            <div>
                <div className="MS_title mt-1">

                </div>
                <div className="matchSchedule">
                    {extraData && data
                        // .filter((match) => match.m3u8)
                        .map((match, index) => {
                            const matchingExtraData = extraData.find(
                                (item) => item.matchId === match.matchId
                            );
                            const score = matchingExtraData
                                ? matchingExtraData.score.replace(",", " - ")
                                : "0 - 0";
                            const statusText = matchingExtraData
                                ? statusMapping[matchingExtraData.status] : "VS";

                            return (
                                <Link
                                    to={`/videopartner/${match.matchId}`}
                                    style={{ textDecoration: "none", color: "white" }}
                                    key={index}
                                >
                                    <div
                                        className="match_schedule"
                                        key={index}
                                    >
                                        <div className="ms-first-row">
                                            <div className="ms-description">
                                                <p>{truncateText(match.league_title, 15)}</p>
                                            </div>
                                            <div className="living-group">
                                                <span>{extractTime(match.startTime)}</span>
                                            </div>
                                        </div>
                                        <div className="match_name">
                                            <div className="match_team_one ">
                                                <div className="text-center">
                                                    <div >
                                                        <img style={{ backgroundColor: 'white', borderRadius: '50%', padding: '1px' }}
                                                            src={match?.localteam_logo || image500ae}

                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevent looping if the fallback also fails
                                                                e.target.src = image500ae; // Replace with the actual path to image500ae
                                                            }}
                                                            width="25px"
                                                            height="25px"
                                                            alt={`${match.localteam_title} logo`}
                                                        />
                                                    </div>
                                                    <p>{truncateText(match.localteam_title, 8)}</p>
                                                </div>
                                            </div>
                                            <div className="match-middle-content_mobile" bis_skin_checked="1">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="match-time_mobile1" bis_skin_checked="1"> <p>{statusText}</p> </div>
                                                </div>
                                                <div className="match-vs_mobile" bis_skin_checked="1"><p>{score}</p></div>
                                            </div>
                                            <div className="match_team_two">
                                                <div className="text-center">
                                                    <div >
                                                        <img style={{ backgroundColor: 'white', borderRadius: '50%', padding: '1px' }}
                                                            src={match?.visitorteam_logo || image500ae}

                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevent looping if the fallback also fails
                                                                e.target.src = image500ae; // Replace with the actual path to image500ae
                                                            }}
                                                            width="25px"
                                                            height="25px"
                                                            alt={`${match.visitorteam_title} logo`}
                                                            className=""
                                                        />
                                                    </div>
                                                    <p> {truncateText(match.visitorteam_title, 5)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="match_time">
                                            <button >
                                                XEM NGAY
                                                <i className="fa-regular fa-circle-play ms-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                </div>
            </div>
        );
    } else {
        return <div className='text-center'>...LOADING KÊNH NHÀ ĐÀI</div>;
    }
};

export default PartnerLive;
