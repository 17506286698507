import React from "react";
import "./Video.css";
import VideoLive from "./VideoLive";
import { Link, useNavigate } from "react-router-dom";

const Video = ({ data }) => {
  const navigate = useNavigate();
  const handleViewNow = (roomId) => {
    window.location.href = `/rooms/${roomId}` ;
  };
  const handleViewNow2 = (roomId) => {
    window.location.href = `/videopartner/${roomId}` ;
  };

  return (
    <div className="video">
      <div className="text-center video_livestream">
        <div className="video_stream ">
          <div className="video_match_live ">
            {data &&
              (
                (
                  <>
                    <VideoLive data={data}></VideoLive>
                  </>
                ))}
          </div>
          {data[0].m3u8 ? 
          <button
            className="MV-button-mobi"
            onClick={() => handleViewNow2(data[0].matchId)}
          >
            VÀO PHÒNG LIVE
          </button> : 
            <button
            className="MV-button-mobi"
            onClick={() => handleViewNow(data[0]._id)}
          >
            VÀO PHÒNG LIVE
          </button> }
        </div>
      </div>
    </div>
  );
};

export default Video;
